var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "my-profile-wrap" },
        [
          !_vm.isNoProfile
            ? _c(
                "page-header",
                { attrs: { title: "주기적으로 업데이트하세요!" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "my-profile-subtitle",
                      attrs: { slot: "subtitle" },
                      slot: "subtitle"
                    },
                    [
                      _vm._v(
                        " 최근 레퍼런스가 많은 프로필의 반응이 훨씬 좋아요."
                      ),
                      _c("br"),
                      _vm._v(" (영상, 사진, 경력 등)"),
                      _c("br"),
                      _c("br"),
                      _vm._v(" 업데이트 전 ’등록 가이드’를 반드시 확인하세요."),
                      _c("br"),
                      _vm._v(" 가이드에 맞지 않는 프로필은 승인이 취소돼요."),
                      _c("br"),
                      _c("br"),
                      _c(
                        "button",
                        {
                          on: {
                            click: function() {
                              return _vm.$refs.ProfileGuideModal.show()
                            }
                          }
                        },
                        [_c("b", [_vm._v("등록 가이드 보기 >")])]
                      ),
                      _c("hr", { staticClass: "mt-5 mb-0" })
                    ]
                  )
                ]
              )
            : _c("page-header", { attrs: { title: "전문가 입점 신청" } }, [
                _c(
                  "div",
                  {
                    staticClass: "my-profile-subtitle",
                    attrs: { slot: "subtitle" },
                    slot: "subtitle"
                  },
                  [
                    _c("br"),
                    _vm._v(" 헤이비글은"),
                    _c("br"),
                    _vm._v(" 상세하고 전문적인 프로필 제공을 통해"),
                    _c("br"),
                    _vm._v(" 쉽고 빠르게 거래하는 비대면 섭외 플랫폼으로,"),
                    _c("br"),
                    _vm._v(" 프로필 심사를 통해 엄선된 분들만"),
                    _c("br"),
                    _vm._v(" 활동 및 입점이 가능합니다."),
                    _c("br"),
                    _c("br"),
                    _vm._v(" 1. '등록 가이드'를 확인하세요."),
                    _c("br"),
                    _vm._v(" 2. 가이드에 맞게 프로필을 작성하세요."),
                    _c("br"),
                    _vm._v(" 3. 100%가 되면 다음을 눌러 심사를 요청하세요."),
                    _c("br"),
                    _vm._v(" 4. 심사 결과를 문자 또는 알림으로 전달드려요."),
                    _c("br"),
                    _c("br"),
                    _c(
                      "button",
                      {
                        on: {
                          click: function() {
                            return _vm.$refs.ProfileGuideModal.show()
                          }
                        }
                      },
                      [_c("b", [_vm._v("[필수] 등록 가이드 보기 >")])]
                    ),
                    _c("hr", { staticClass: "mt-5 mb-0" })
                  ]
                )
              ]),
          _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-0" }),
          _vm.isNoProfile === false
            ? [
                _c(
                  "div",
                  {
                    staticClass: "my-profile-btn ui-btn",
                    on: {
                      click: function($event) {
                        return _vm.$router.push(_vm.$route.path + "/event-list")
                      }
                    }
                  },
                  [_vm._m(0)]
                ),
                _c("div", { staticClass: "ui-border-line" })
              ]
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "my-profile-btn ui-btn",
              on: {
                click: function($event) {
                  return _vm.$router.push(_vm.$route.path + "/detail-main")
                }
              }
            },
            [
              _vm._m(1),
              _c("div", { staticClass: "percent-number" }, [
                _vm._v(_vm._s(_vm.profilePercent || 0) + "%")
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "my-profile-btn ui-btn",
              on: {
                click: function($event) {
                  return _vm.$router.push(_vm.$route.path + "/privacy")
                }
              }
            },
            [
              _c("div", { staticClass: "text" }, [
                _c(
                  "div",
                  { staticClass: "title", style: { lineHeight: "6.2rem" } },
                  [_vm._v("정산 정보")]
                )
              ]),
              _vm.artistProfilePrivacyChecked
                ? _c("icon", {
                    attrs: {
                      src: "img/common/check-green.png",
                      width: "2rem",
                      height: "11rem",
                      cover: false
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "ui-border-line" }),
          _c(
            "div",
            {
              staticClass: "my-profile-btn ui-btn",
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    _vm.$route.path + "/fee-information-and-agreement/1"
                  )
                }
              }
            },
            [
              _vm._m(2),
              _vm.feeInformationAndAgreement
                ? _c("icon", {
                    attrs: {
                      src: "img/common/check-green.png",
                      width: "2rem",
                      height: "11rem",
                      cover: false
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "text-block" }, [
            _c("p", [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$gotoWeb("http://pf.kakao.com/_yzxhwC/chat")
                    }
                  }
                },
                [_vm._v("도움이 필요하다면 >")]
              )
            ])
          ]),
          _vm.isNoProfile
            ? _c("footer-box", {
                attrs: {
                  submitText: "다음",
                  submitCb: function() {
                    return _vm.$router.push(
                      "/artist/my-profile/preview?postProfile=true"
                    )
                  },
                  submitDisabled:
                    _vm.profilePercent !== 100 ||
                    _vm.feeInformationAndAgreement === false
                }
              })
            : _vm.step < 2
            ? _c("footer-box", {
                attrs: {
                  submitText: "다음",
                  submitCb: function() {
                    return _vm.$router.push(
                      "/artist/my-profile/preview?postProfile=true"
                    )
                  },
                  submitDisabled:
                    _vm.profilePercent !== 100 ||
                    _vm.feeInformationAndAgreement === false,
                  single: false,
                  btnText: "프로필 삭제",
                  btnCb: function() {
                    return _vm.deleteProfile()
                  },
                  btnDisabled: false
                }
              })
            : _vm.step == 2
            ? _c("footer-box", {
                attrs: {
                  submitText: "다음",
                  submitCb: function() {
                    return _vm.$router.push(
                      "/artist/my-profile/preview?postProfile=true"
                    )
                  },
                  submitDisabled:
                    _vm.profilePercent !== 100 ||
                    _vm.feeInformationAndAgreement === false,
                  single: false,
                  btnText: "내 프로필 보기",
                  btnCb: function() {
                    return _vm.$router.push(
                      "/artist/profile/" + _vm.artistData.artistidx
                    )
                  },
                  btnDisabled: _vm.profilePercent !== 100
                }
              })
            : _c("footer-box", {
                attrs: {
                  submitText: "내 프로필 보기",
                  submitCb: function() {
                    return _vm.$router.push(
                      "/artist/profile/" + _vm.artistData.artistidx
                    )
                  },
                  submitDisabled: _vm.profilePercent !== 100,
                  single: false,
                  btnText: "비공개하기",
                  btnCb: function() {
                    return _vm.hideProfile()
                  },
                  btnDisabled: false
                }
              })
        ],
        2
      ),
      _c("ArtistGuideModal", { ref: "ArtistGuideModal" }),
      _c(
        "page-modal",
        {
          ref: "ProfileGuideModal",
          class: "guide-profile close-right",
          attrs: { title: "프로필 등록 가이드" }
        },
        [
          _c(
            "div",
            {
              staticClass: "page-modal-body",
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _c("div", { staticClass: "page-sub-title" }, [
                _vm._v(
                  " 헤이비글은 상세하고 전문적인 프로필을 통해 빠르게 공연 및 행사 매칭을 할 수 있는 "
                ),
                _c("strong", [_vm._v("플랫폼")]),
                _vm._v("입니다."),
                _c("br"),
                _vm._v(" 전문성이 매우 중요한 만큼 자체 "),
                _c("strong", [_vm._v("심사를 통해 엄선된 분들만 활동이 가능")]),
                _vm._v("합니다. ")
              ]),
              _c(
                "div",
                { staticClass: "guideLetter" },
                [
                  _c("icon", {
                    attrs: {
                      src: "img/my-profile/megaphone.png",
                      width: "1.8rem",
                      height: "1.8rem",
                      cover: false
                    }
                  }),
                  _vm._v(
                    " 최대한 자세하고 전문성이 돋보이도록 프로필을 작성해주세요. "
                  )
                ],
                1
              ),
              _c("div", { staticClass: "profile-sec-tit" }, [
                _vm._v("1. 심사일정")
              ]),
              _c("div", { staticClass: "profile-registration-step-wrap" }, [
                _c("ol", { staticClass: "profile-registration-step" }, [
                  _c("li", [
                    _c("img", {
                      attrs: {
                        src: "/img/my-profile/step01@2x.png",
                        alt: "프로필 등록 가이드 step1 아이콘 - 시계"
                      }
                    }),
                    _c("div", [
                      _c("div", { staticClass: "step-tit" }, [
                        _vm._v("Step 1")
                      ]),
                      _c("div", { staticClass: "step-txt" }, [
                        _vm._v(
                          "‘입점 심사’ 요청 후 최대 24시간 내 진행 (공휴일 제외, 평일에만 진행)"
                        )
                      ])
                    ])
                  ]),
                  _c("li", [
                    _c("img", {
                      attrs: {
                        src: "/img/my-profile/step02@2x.png",
                        alt: "프로필 등록 가이드 step2 아이콘 - 메세지"
                      }
                    }),
                    _c("div", [
                      _c("div", { staticClass: "step-tit" }, [
                        _vm._v("Step 2")
                      ]),
                      _c("div", { staticClass: "step-txt" }, [
                        _vm._v("확인 후 개별적으로 결과 메시지 발송")
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "notice-area" }, [
                  _c("div", { staticClass: "notice-tit" }, [
                    _vm._v("※ 심사 거부 대상")
                  ]),
                  _c("ul", { staticClass: "notice-list" }, [
                    _c("li", [_vm._v("등록 가이드에 맞지 않은 프로필")]),
                    _c("li", [_vm._v("무성의한 프로필")]),
                    _c("li", [_vm._v("공연, 행사 무경험자")])
                  ])
                ])
              ]),
              _c("div", { staticClass: "profile-sec-tit" }, [
                _vm._v("섭외율 높이는 "),
                _c("span", { staticClass: "line-badge" }, [_vm._v("Tip")])
              ]),
              _c("ul", { staticClass: "tip-list" }, [
                _c("li", [
                  _vm._v(
                    "첫번째 사진은 첫인상과 같아요. 가장 자신있는 사진을 등록하세요."
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "영상은 다다익선! 행사, 공연 영상이 많은 분들이 섭외율이 높아요. (실력 확인이 되니깐요!)"
                  )
                ]),
                _c("li", [
                  _vm._v("활동경력을 상세하게 적으면 더 어필이 되겠죠?")
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "title" }, [
        _c("i", { staticStyle: { color: "red", "font-style": "normal" } }),
        _vm._v(" 이벤트, 프로모션 등록")
      ]),
      _c("div", [
        _vm._v("이벤트 등록하고 더 많은 섭외 기회와 수익을 늘리세요!")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "title" }, [_vm._v("프로필 정보")]),
      _c("div", [_vm._v("기본 정보, 상세 정보, 활동 정보")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "title" }, [_vm._v("정책 안내 및 필수 동의")]),
      _c("div", [_vm._v("수수료, 정산일, 환불규정, 운영정책 등")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }