<template>
    <div class="container">
        <div class="my-profile-wrap">
            <page-header
                v-if="!isNoProfile"
                title="주기적으로 업데이트하세요!"
                >
                <div slot="subtitle" class="my-profile-subtitle">
                    최근 레퍼런스가 많은 프로필의 반응이 훨씬 좋아요.<br>
                    (영상, 사진, 경력 등)<br><br>
                    업데이트 전 ’등록 가이드’를 반드시 확인하세요.<br>
                    가이드에 맞지 않는 프로필은 승인이 취소돼요.<br>
                    <br>
                    <button @click="() => $refs.ProfileGuideModal.show()"><b>등록 가이드 보기 ></b></button>
                    <hr class="mt-5 mb-0">
                </div>
            </page-header>

            <page-header
                v-else
                title="전문가 입점 신청"
                >
                <div slot="subtitle" class="my-profile-subtitle">
                    <br>
                    헤이비글은<br>
                    상세하고 전문적인 프로필 제공을 통해<br>
                    쉽고 빠르게 거래하는 비대면 섭외 플랫폼으로,<br>
                    프로필 심사를 통해 엄선된 분들만<br>
                    활동 및 입점이 가능합니다.<br>
                    <br>
                    1. '등록 가이드'를 확인하세요.<br>
                    2. 가이드에 맞게 프로필을 작성하세요.<br>
                    3. 100%가 되면 다음을 눌러 심사를 요청하세요.<br>
                    4. 심사 결과를 문자 또는 알림으로 전달드려요.<br>
                    <br>
                    <button @click="() => $refs.ProfileGuideModal.show()"><b>[필수] 등록 가이드 보기 ></b></button>
                    <hr class="mt-5 mb-0">
                </div>
            </page-header>

            <div class="ui-border-line ui-h-0 ui-mb-0"></div>

            <!-- <div class="my-profile-progress">
                <div class="my-profile-progress-percent">
                    <div class="percent-number">{{profilePercent || 0}}%</div>
                    <div class="percent-bar">
                        <div class="percent-bar-outline">
                            <div class="percent-bar-value" :style="{ width: profilePercent + '%' || 0 }"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui-border-line ui-h-1 ui-mt-3" style="height: 10px"></div> -->

            <!-- 멤버십 무료전환 - 미노출 영역 주석 처리-->
            <!-- <template v-if="isNoProfile === false">
                <div
                    class="my-profile-btn ui-btn"
                    @click="() => $router.push('/new-membership')"
                >
                    <div class="text">
                        <div class="title"><i style="color: red; font-style: normal">추천</i> 플러스 멤버십</div>
                        <div>더 많은 어필과 고객 접점 기회를 얻으세요.</div>
                    </div>
                    <span
                        v-if="isNewMembershipUser"
                        class="ui-weight-5"
                        :style="{
                            color: '#000',
                        }"
                    >
                        {{moment(+artistData.newMemberDate * 1000).format('YY.MM.DD')}} 만료
                    </span>
                </div>

                <div class="ui-border-line"></div>
            </template> -->

            <template v-if="isNoProfile === false">
                <div class="my-profile-btn ui-btn" @click="$router.push($route.path + '/event-list')">
                    <div class="text">
                        <div class="title"><i style="color: red; font-style: normal"></i> 이벤트, 프로모션 등록</div>
                        <div>이벤트 등록하고 더 많은 섭외 기회와 수익을 늘리세요!</div>
                    </div>
                </div>

                <div class="ui-border-line"></div>
            </template>

            <div class="my-profile-btn ui-btn" @click="$router.push($route.path + '/detail-main')">
                <div class="text">
                    <div class="title">프로필 정보</div>
                    <div>기본 정보, 상세 정보, 활동 정보</div>
                </div>
                <div class="percent-number">{{profilePercent || 0}}%</div>
            </div>

            <div class="my-profile-btn ui-btn" @click="$router.push($route.path + '/privacy')">
                <div class="text">
                    <div class="title" :style="{lineHeight: '6.2rem'}">정산 정보</div>
                </div>
                <icon v-if="artistProfilePrivacyChecked" src="img/common/check-green.png" width="2rem" height="11rem" :cover="false" />
            </div>

            <div class="ui-border-line"></div>

            <div class="my-profile-btn ui-btn" @click="$router.push($route.path + '/fee-information-and-agreement/1')">
                <div class="text">
                    <div class="title">정책 안내 및 필수 동의</div>
                    <div>수수료, 정산일, 환불규정, 운영정책 등</div>
                </div>
                <icon v-if="feeInformationAndAgreement" src="img/common/check-green.png" width="2rem" height="11rem" :cover="false" />
            </div>
            <div class="text-block">
                <p><button
                    @click="$gotoWeb('http://pf.kakao.com/_yzxhwC/chat')"
                >도움이 필요하다면 ></button></p>
            </div>

            <footer-box
                v-if="isNoProfile"
                submitText="다음"
                :submitCb="() => $router.push('/artist/my-profile/preview?postProfile=true')"
                :submitDisabled="profilePercent !== 100 || feeInformationAndAgreement === false"
            />
            <footer-box
                v-else-if="step < 2"
                submitText="다음"
                :submitCb="() => $router.push('/artist/my-profile/preview?postProfile=true')"
                :submitDisabled="profilePercent !== 100 || feeInformationAndAgreement === false"
                :single="false"
                btnText="프로필 삭제"
                :btnCb="() => deleteProfile()"
                :btnDisabled="false"
            />
            <footer-box
                v-else-if="step == 2"
                submitText="다음"
                :submitCb="() => $router.push('/artist/my-profile/preview?postProfile=true')"
                :submitDisabled="profilePercent !== 100 || feeInformationAndAgreement === false"
                :single="false"
                btnText="내 프로필 보기"
                :btnCb="() => $router.push(`/artist/profile/${artistData.artistidx}`)"
                :btnDisabled="profilePercent !== 100"
            />
            <footer-box
                v-else
                submitText="내 프로필 보기"
                :submitCb="() => $router.push(`/artist/profile/${artistData.artistidx}`)"
                :submitDisabled="profilePercent !== 100"
                :single="false"
                btnText="비공개하기"
                :btnCb="() => hideProfile()"
                :btnDisabled="false"
            />
        </div>

        <!-- <page-modal
            ref="pageModal"
            :title="'프로필 등록 완료!'"
            :subtitle="'아래의 이용과정 숙지 후,\n원하는 조건의 의뢰를 찾아 마음껏 지원하세요.'"
            useBorderLine
            :hideCb="() => pageModalOnHide()"
        >
            <div slot="body" class="page-modal-body">
                <h4>1. 의뢰에 지원하기</h4>
                <p class="pl-5">{{'원하는 의뢰를 찾아 지원하세요.\n프로필과 지원 내용이 함께 발송돼요.'}}</p>
            </div>
            <div slot="body" class="page-modal-body">
                <h4>2. 상담하기</h4>
                <p class="pl-5">{{'어필이 잘 되었다면 연락이 올거에요.\n구체적인 상담을 진행해주세요.'}}</p>
            </div>
            <div slot="body" class="page-modal-body">
                <h4>3. 섭외/결제 요청하기</h4>
                <p class="pl-5">{{`의뢰인에게 '안전 결제'를 요청하세요.\n안전 결제가 되어야 예약이 완료됩니다.`}}</p>
            </div>
            <div slot="body" class="page-modal-body">
                <h4>4. 의뢰 진행하기</h4>
                <p class="pl-5">{{`결제가 되었다면 의뢰를 진행하세요.\n대금은 의뢰 종료 후 입금됩니다. `}}<button @click="() => $router.push('/etc/fee-information')">자세히 알아보기</button></p>
            </div>
            <div slot="body" class="page-modal-body">
                <h4>5. 리뷰 요청하기</h4>
                <p class="pl-5">{{`리뷰가 많을수록 진행률이 2배 이상 높아져요!\n의뢰인에게 꼭! 리뷰를 요청하세요.`}}</p>
            </div>
        </page-modal> -->

        <ArtistGuideModal ref="ArtistGuideModal" />
        <!-- 프로필 등록 가이드 -->
        <page-modal
            ref="ProfileGuideModal"
            :title="'프로필 등록 가이드'"
            :class="'guide-profile close-right'"
            >
            <div slot="body" class="page-modal-body">
                <div class="page-sub-title">
                    헤이비글은 상세하고 전문적인 프로필을 통해 빠르게 공연 및 행사 매칭을 할 수 있는 <strong>플랫폼</strong>입니다.<br />
                    전문성이 매우 중요한 만큼 자체 <strong>심사를 통해 엄선된 분들만 활동이 가능</strong>합니다.
                </div>
                <div class="guideLetter">
                    <icon src="img/my-profile/megaphone.png" width="1.8rem" height="1.8rem" :cover="false" />
                    최대한 자세하고 전문성이 돋보이도록 프로필을 작성해주세요.
                </div>
                <div class="profile-sec-tit">1. 심사일정</div>
                <div class="profile-registration-step-wrap">
                    <ol class="profile-registration-step">
                        <li>
                            <img src="/img/my-profile/step01@2x.png" alt="프로필 등록 가이드 step1 아이콘 - 시계">
                            <div>
                            <div class="step-tit">Step 1</div>
                            <div class="step-txt">‘입점 심사’ 요청 후 최대 24시간 내 진행 (공휴일 제외, 평일에만 진행)</div>
                            </div>
                        </li>
                        <li>
                            <img src="/img/my-profile/step02@2x.png" alt="프로필 등록 가이드 step2 아이콘 - 메세지">
                            <div>
                            <div class="step-tit">Step 2</div>
                            <div class="step-txt">확인 후 개별적으로 결과 메시지 발송</div>
                            </div>
                        </li>
                    </ol>
                    <div class="notice-area">
                        <div class="notice-tit">※ 심사 거부 대상</div>
                        <ul class="notice-list">
                            <li>등록 가이드에 맞지 않은 프로필</li>
                            <li>무성의한 프로필</li>
                            <li>공연, 행사 무경험자</li>
                        </ul>
                    </div>
                </div>

                <div class="profile-sec-tit">섭외율 높이는 <span class="line-badge">Tip</span></div>
                <ul class="tip-list">
                    <li>첫번째 사진은 첫인상과 같아요. 가장 자신있는 사진을 등록하세요.</li>
                    <li>영상은 다다익선! 행사, 공연 영상이 많은 분들이 섭외율이 높아요. (실력 확인이 되니깐요!)</li>
                    <li>활동경력을 상세하게 적으면 더 어필이 되겠죠?</li>
                </ul>
            </div>
        </page-modal>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import PageModal from '@/components/common/PageModal'
import Modal from '@/components/common/Modal'
import ArtistGuideModal from '@/components/modal/ArtistGuideModal.vue'

export default {
    name: 'MyProfile',
    components: {
        PageHeader,
        Icon,
        FooterBox,
        PageModal,
        Modal,
        ArtistGuideModal,
    },
    data() {
      return {
        profile: {},
      }
    },
    computed: {
        profilePercent() {
            return this.profile.percent
        },
        step() {
            return this.$store.getters.getArtistProfileStep || 0
        },
        artistData: {
            get() {
                return this.$store.getters.getArtistData
            },
            set(payload) {
                if (typeof payload === 'object' && payload) {
                    this.$store.commit('setArtistData', payload)
                }
            },
        },
        isNoProfile() {
            return this.profile.artistidx == null
        },
        isNewMembershipUser() {
            return this.$store.getters.isNewMembershipUser
        },
        artistAccountInfo() {
            return this.$store.getters.getArtistAccountInfo
        },
        artistProfilePrivacyChecked() {
            return this.artistAccountInfo.checkImgUrl && this.artistAccountInfo.bankImgUrl ? true : false;
        },
        feeInformationAndAgreement() {
            return this.artistAccountInfo.agreeDate ? true : false;
        },
    },
    mounted() {
        FB.AppEvents.logEvent('click', {desc:`전문가 > 프로필 등록 페이지`})
        this.getAccountInfo();
        if(this.$route.query.artistidx){
            this.getProfileInfo(this.$route.query.artistidx);
        }else if(this.artistData.artistidx != null){
            this.getProfileInfo(this.artistData.artistidx);
        }else {
            this.$store.commit('setArtistData', null)
            this.$store.commit('setArtistProfileStep', 1)
        }
        // 멤버십 테스트
        // this.$store.dispatch('saveProfile', {
            // newMemberDate: '0',
            // newMemberDate: moment().add(30, 'd').valueOf().toString().slice(0, 10),
        // })
    },
    beforeRouteEnter(to, from, next) {
        next(Vue => {
            Vue.$store.dispatch('getArtistEventList')
            Vue.$store.commit('setGnbLeftBtnCb', () => {
                Vue.$router.push('/profile')
            })
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('setGnbLeftBtnCb', false)
        setTimeout(() => {
            if (this.$store.state.history.back) {
                next('/profile')
            } else {
                next()
            }
        })
    },
    methods: {
        hideProfile(isConfirm = false) {
            if (!isConfirm) {
                this.$store.commit('setModal', {
                    show: true,
                    title: '앗, 비공개를??!',
                    body: '다시 공개하려면\n등록 신청이 필요해요.\n그래도 비공개하시겠어요?',
                    cancel: '닫기',
                    confirm: '비공개하기',
                    confirmCb: modal => {
                        this.hideProfile(true)
                        modal?.hide?.()
                    },
                })
                return
            }

            const req = {
                method: 'put',
                url: `/artist/profile/disable/${this.artistData.artistidx}`,
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        this.artistData = { confirmDate: 0 }
                        this.$toast('프로필이 비공개로 변경되었습니다')
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
        deleteProfile(isConfirm = false) {
            if (!isConfirm) {
                this.$store.commit('setModal', {
                    show: true,
                    title: '앗, 프로필 삭제를??!',
                    body: '삭제된 정보는\n복구가 불가합니다.\n정말 삭제하시겠습니까?',
                    cancel: '취소',
                    confirm: '삭제',
                    confirmCb: modal => {
                        this.deleteProfile(true)
                        modal?.hide?.()
                    },
                })
                return
            }

            const req = {
                method: 'delete',
                url: `/artist/profile/${this.artistData.artistidx}`,
            }

            this.$http(req)
                .then(({ data }) => {
                    // 프로필 다시 불러오기 
                    this.$store.dispatch('getProfileList')
                    this.$router.push('/profile')
                })
                .catch(log.error)
        },
        getProfileInfo(artistidx) {
          if (!artistidx) return Promise.resolve()

          const req = {
            method: 'get',
            url: `/artist/profile/info/${artistidx}`,
          }

          return this.$http(req)
            .then(({ data }) => {
                log(...Object.values(req), data)

                const resultData = this.$lib.resultCheck(data.resultData)

                if (resultData.success) {
                    let artistData = data.response.artistData || {}
                    let artistProfileStep = data.response.artistProfileStep || {}
                    if(artistData){
                        this.profile = artistData
                        this.$store.commit('setArtistData', artistData)
                    }
                    if(artistProfileStep){
                        this.$store.commit('setArtistProfileStep', artistProfileStep)
                    }
                } else {
                    this.$alert(resultData)
                }
                return
            })
            .catch((e) => {
                log.error(e)
                return
            })
        },
        getAccountInfo() {
          const req = {
            method: 'get',
            url: `/artist/accountInfo`,
          }

          return this.$http(req)
            .then(({ data }) => {
                log(...Object.values(req), data)

                const resultData = this.$lib.resultCheck(data.resultData)
                if (resultData.success) {
                    let response = data.response || {}
                    if(response){
                        this.$store.commit('setArtistAccountInfo', response);
                    }
                } else {
                    this.$alert(resultData)
                }
                return
            })
            .catch((e) => {
                log.error(e)
                return
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page > .container {
    background-color: #f0f3f9;
}

.page-header {
    padding-bottom: 0;
}

.my-profile-wrap {
    background-color: #fff;
    margin: 0 -3rem;
    padding: 0 3rem;
}

.my-profile-subtitle {
    font-size: 20px;

    button {
        color: #000;
        font-weight: 500;
        // color: $color-link-blue;
        // text-decoration: underline;
        // text-underline-position: under;
    }
}

.my-profile-progress {
    p {
        margin: 0;
        color: #A6A6A6;
        font-size: 1.6rem;
    }
    .my-profile-progress-percent {
        overflow: hidden;

        .percent-number {
            float: left;
            margin-right: 2.7rem;
            color: $color-deepLavender;
            font-size: 3rem;
            line-height: 5rem;
            font-weight: 500;
        }
        .percent-bar {
            float: left;
            margin: 1.6rem 0;

            .percent-bar-outline {
                overflow: hidden;
                width: 36rem;
                height: 1.2rem;
                border: 1px solid #dedede;
                border-radius: 0.6rem;

                .percent-bar-value {
                    width: 0;
                    height: 1rem;
                    background-color: $color-deepLavender;
                    border-radius: 0.4rem;
                }
            }
        }
    }
}

.my-profile-btn {
    position: relative;
    height: 10rem;
    padding: 2.4rem 0;
    .text {
        color: #a3a3a3;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.5;
        .title {
            font-size: 2.2rem;
            color: #000;
            font-weight: 500;
        }
    }
    span {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: inline-block;
        height: 5rem;
        font-size: 1.8rem;
        font-weight: 400;
        color: $color-deepLavender;
    }
    .icon {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .percent-number {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: $color-deepLavender;
        font-size: 3rem;
        line-height: 5rem;
        font-weight: 500;
    }
}

.text-block {
    margin: 0 -3rem;
    background-color: #f7f7f7;
    height: 11rem;
    display: flex;
    align-items: center;
    padding: 0 3rem;
    button {
        color: $color-link-blue;
    }
}
</style>
